/**
 * App JS
 *
 * Author: Luca Rosaldi
 * Author URI: https://twitter.com/LucaRosaldi
 *
 * @version  1.0
 */
;var App = ( function( window, document, undefined ) {
  'use strict';

  var w    = window,
      d    = document,
      g    = d.documentElement,
      body = d.body,
      wW   = w.innerWidth  || g.clientWidth  || body.clientWidth,
      wH   = w.innerHeight || g.clientHeight || body.clientHeight,
      wY   = w.pageYOffset || g.scrollTop    || body.scrollTop;

  // last scroll direction for the Y axis ( 'up' or 'down' )
  var lastScrollDirection;

  /**
   * Bind all events for the app.
   */
  var initEvents = function() {

    windowResizeListener();
    windowScrollListener();

    bodyScrollClassListener( 50 );
    smoothScrollListener();
    inputFillListener();
    sliderInit();

    pageLoadInit();

  }

  //******************************************************
  // EVENTS
  //******************************************************

  /**
   * Actions to perform when the DOM has loaded.
   *
   * - Remove loading class from the body
   * - Init Fastclick
   * - Init parallax effect
   * - Play intro animation
   */
  var pageLoadInit = function() {
    w.addEventListener( 'DOMContentLoaded', function() {
      $( body ).removeClass( 'is-loading' );
      FastClick.attach( body );
    });
  }

  /**
   * Update window size values on resize event.
   */
  var windowResizeListener = function() {
    w.addEventListener( 'resize', debounce( function() {
      wW = w.innerWidth  || g.clientWidth  || body.clientWidth;
      wH = w.innerHeight || g.clientHeight || body.clientHeight;
    }, 500));
  }

  /**
   * Update window top offset and scroll direction on scroll event.
   */
  var windowScrollListener = function() {
    w.addEventListener( 'scroll', throttle( function() {
      var sp = w.pageYOffset || g.scrollTop || body.scrollTop;
      lastScrollDirection = ( sp > wY ) ? 'down' : 'up';
      wY = sp;
    }, 500));
  }

  /**
   * Scroll checker.
   * Add a scrolled class to the body when page has scrolled.
   *
   * @param  {Integer}  scrollOffset  Number of pixels scrolled before class is applied
   */
  var bodyScrollClassListener = function( scrollOffset ) {
    var scrollOffset = scrollOffset || 100,
        scrolledPast = wY >= scrollOffset;

    if ( scrolledPast ) {
      classie.add( body, 'is-scrolled' );
    }

    w.addEventListener( 'scroll', throttle( function() {

      // page is scrolling down while the class has been added already. Do nothing.
      if (
        lastScrollDirection === 'down' &&  scrolledPast ||
        lastScrollDirection === 'up'   && !scrolledPast
      ) {
        return;
      }

      // page has scrolled down below the scroll offset, add the scrolled class
      if ( ! classie.has( body, 'is-scrolled' ) && wY >= scrollOffset ) {
        classie.add( body, 'is-scrolled' );
        scrolledPast = true;
      }

      // page has scrolled up above the scroll offset, remove the scrolled class
      if ( lastScrollDirection === 'up' && wY <= scrollOffset ) {
        classie.remove( body, 'is-scrolled' );
        scrolledPast = false;
      }

    }, 500));
  }

  /**
   * Toggle the offcanvas navigation.
   */
  function offcanvasButtonListener() {
    var buttons = d.querySelectorAll( '.js-offcanvas-toggle' );
    [].forEach.call( buttons, function( button ) {
      button.addEventListener( 'click', function( ev ) {
        ev.preventDefault();
        ev.stopImmediatePropagation();
        classie.toggle( body, 'offcanvas-is-active' );
        classie.toggle( button, 'is-active' );
      });
    });
  }

  /**
   * Smooth scroll animation, using GSAP.
   */
  function smoothScrollListener() {
    var anchorLinks = d.querySelectorAll( '[href^="#"]' );

    [].forEach.call( anchorLinks, function( link ) {
      link.addEventListener( 'click', function( ev ) {
        ev.preventDefault();
        ev.stopImmediatePropagation();

        var target = d.querySelector( ev.target.getAttribute('href') ) || '';
        if ( ! target ) { return; }

        TweenLite.to(
          w, 1, {
            scrollTo: { y: getOffsetTop( target ) },
            ease: Power3.easeOut
          }
        );

      });
    });
  }

  /**
   * Assign a 'is-filled' class to text inputs which contain a value
   */
  var inputFillListener = function() {
    var inputs = d.querySelectorAll( '.js-input-filled' );

    [].forEach.call( inputs, function( input ) {
      addFilledClass( input );
      input.addEventListener( 'change', function() { addFilledClass( input ); } );
    });

    function addFilledClass( input ) {
      if ( input.value.length > 0 ) {
        classie.add( input, 'is-filled' );
      } else {
        classie.remove( input, 'is-filled' );
      }
    }

  };

  /**
   * Initialize Flexslider.
   */
  var sliderInit = function() {
    $('.js-slider').flexslider({
      animation: "slide",
      namespace: "slider_",
      pauseOnHover: true,
      prevText: "",
      nextText: "",
      before: function( slider ) {
      },
      after: function( slider ) {
      }
    });
  };

  //******************************************************
  // ANIMATIONS (GSAP)
  //******************************************************

  /**
   * The loading entrance animation.
   */
  var animIntro = function() {

    var tl = new TimelineMax({ paused: true }),
        frame;

    // First frame: remove loading screen
    frame = d.querySelector( '[data-intro-sequence="1"]' );
    if ( frame ) {
      tl.to( frame, 0.15, {
        autoAlpha: 0,
        ease: Power1.easeOut
      });
    }

    // Second frame: slide in items from top
    frame = d.querySelector( '[data-intro-sequence="2"]' );
    if ( frame ) {
      TweenLite.set( frame, { yPercent: -100 } );
      tl.to( frame, 0.35, {
        yPercent: 0,
        ease:  Circ.easeOut
      }, '+0.50' );
    }

    // Third frame: slide in items from bottom
    frame = d.querySelector( '[data-intro-sequence="3"]' );
    if ( frame ) {
      TweenLite.set( frame, { yPercent: 100 } );
      tl.to( frame, 0.35, {
        yPercent: 0,
        ease:  Circ.easeOut
      }, '+1.10' );
    }

    // Fourth frame: fade and slide in items.
    frame = d.querySelectorAll( '[data-intro-sequence="4"]' );
    if ( frame ) {
      TweenLite.set( frame, { y: '-44px', opacity: 0 } );
      tl.staggerTo( frame, 0.35, {
        y: 0,
        opacity: 1,
        ease:  Circ.easeOut
      }, 0.25, '+1.6' );
    }

    // Fifth frame: scale in items.
    frame = d.querySelector( '[data-intro-sequence="5"]' );
    if ( frame ) {
      TweenLite.set( frame, { opacity: 0, scale: 0.01 } );
      tl.to( frame, 0.35, {
        scale: 1,
        opacity: 1,
        ease:  Back.easeIn
      }, '+2' );
    }

    tl.play();

  };

  //******************************************************
  // HELPERS
  //******************************************************

  /**
   * Returns a function that, when invoked, will only be triggered at most once
   * during a given window of time. Normally, the throttled function will run as
   * much as it can, without ever going more than once per wait duration; but if
   * you'd like to disable the execution on the leading edge, pass {leading:
   * false}. To disable execution on the trailing edge, ditto.
   *
   * @param  {function} func      Function to debounce.
   * @param  {integer}  wait      Time to wait before function is fired again.
   * @param  {object}   options   Function options (see full description).
   */
  var throttle = function( func, wait, options ) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if ( !options ) { options = {}; }
    var later = function() {
      previous = new Date();
      timeout = null;
      result = func.apply(context, args);
    };
    return function() {
      var now = new Date();
      if (!previous && options.leading === false) { previous = now; }
      var remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0) {
        clearTimeout(timeout);
        timeout = null;
        previous = now;
        result = func.apply(context, args);
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  };

  /**
   * Returns a function that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * N milliseconds. If `immediate` is passed, trigger the function on the
   * leading edge, instead of the trailing.
   *
   * @param  {function} func       Function to debounce.
   * @param  {integer}  wait       Time to wait before function is fired again.
   * @param  {boolean}  immediate  Trigger function on leading edge instead of trailing.
   */
  var debounce = function( func, wait, immediate ) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) { func.apply(context, args); }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) { func.apply(context, args); }
    };
  };

  /**
   * Get the offset position of an element, relative to the viewport.
   *
   * @param  {object} elem  The element to get the offset for.
   * @return {object}       The top offset.
   */
  var getOffsetTop = function( elem ) {

    // Get the enclosing rectangle;
    var box = elem.getBoundingClientRect();

    // The document (`html` or `body`) can be shifted
    // from left-upper corner in IE. Get the shift.
    var clientTop  = g.clientTop  || body.clientTop  || 0;

    // Add scrolls to window-relative coordinates
    // and substract the shift of `html/body` to
    // get coordinates in the whole document
    var top  = box.top +  wY - clientTop;

    return Math.round( top );
  };

  //******************************************************
  // CONSTRUCTOR
  //******************************************************

  return { init: function() { initEvents(); } };

})( this, this.document );
App.init();