/**
 * Input file module.
 *
 * Author: Luca Rosaldi
 * Author URI: https://twitter.com/LucaRosaldi
 *
 * @version  1.0.0
 *
 * Script for customized input file. Changes the label text
 * to reflect the file name which was selected by the user.
 * @see  http://tympanus.net/Tutorials/CustomFileInputs/
 *
 * Example markup:
 *

<input
  id="my-field"
  class="file-input js-file-input"
  type="file"
  name="file"
  [multiple] [data-multiple-caption="{count} files selected."] />
<label for="my-field">
  [<span class="icon icon--upload">]
  <span>Choose a file</span>
</label>

 *
 */
;var FileInput = ( function() {
  'use strict';

  /**
   * Update the label to reflect the selected files.
   *
   * @param  {object} event    Event object
   * @param  {[type]} label    Label element
   * @param  {[type]} labelVal Label text
   */
  function updateLabel( event, input, label, labelVal ) {

    var fileName = '';

    // When multiple files are selected, the label displays the number of files
    if ( this.files && this.files.length > 1 ) {
      fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' )
        .replace( '{count}', this.files.length );
    }
    // When a single file is selected, the label displays the file name
    else {
      fileName = event.target.value.split( '\\' ).pop();
    }

    // Change or restore the label text, and set a class to indicate whether some file was selected
    if ( fileName ) {
      input.classList.add( 'is-selected' );
      label.innerHTML = fileName;
    }
    else {
      input.classList.remove( 'is-selected' );
      label.innerHTML = labelVal;
    }

  }

  return {
    init: function( selector ) {

      var inputs = document.querySelectorAll( selector );

      [].forEach.call( inputs, function( input ) {

        // If the label has a inner <span>, choose that as the label text node
        // This way, we can use an icon inside the <label> element, in addition to the text
        var label    = input.nextElementSibling.querySelector('span') || input.nextElementSibling;
        var labelVal = label.innerHTML;

        input.addEventListener( 'change', function( event ) {
          updateLabel.call( this, event, input, label, labelVal );
        });

      });
      // endforeach

    }
  };
})();
FileInput.init('.js-file-input');